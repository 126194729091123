@use "@angular/material" as mat;
@include mat-core();

:root {
  --dark-text: #2c2c2c;
  --dark-primary-text: rgba(#2c2c2c, 0.87);
  --dark-accent-text: rgba(#2c2c2c, 0.54);
  --dark-disabled-text: rgba(#2c2c2c, 0.38);
  --dark-dividers: rgba(#2c2c2c, 0.12);
  --dark-focused: rgba(#2c2c2c, 0.12);

  --light-text: #fafafa;
  --light-primary-text: #fafafa;
  --light-accent-text: rgba(#fafafa, 0.7);
  --light-disabled-text: rgba(#fafafa, 0.5);
  --light-dividers: rgba(#fafafa, 0.12);
  --light-focused: rgba(#fafafa, 0.12);

  --primary-lighter-color: #f1f5ff;
  --primary-darker-color: #010b6c;
  --text-primary-color: #{#fafafa};
  --text-primary-lighter-color: #{#2c2c2c};
  --text-primary-darker-color: #{#fafafa};

  --primary-50: #e1e3f1;
  --primary-100: #b3b8dc;
  --primary-200: #8089c4;
  --primary-300: #4d5aac;
  --primary-400: #27369b;
  --primary-color: #011389; //500
  --primary-600: #011181;
  --primary-700: #010e76;
  --primary-800: #010b6c;
  --primary-900: #000659;
  --primary-A100: #8b8cff;
  --primary-A200: #585aff;
  --primary-A400: #2528ff;
  --primary-A700: #0b0fff;


  --accent-50: #fbfcfd;
  --accent-100: #f6f7fb;
  --accent-200: #f0f2f8;
  --accent-300: #e9ecf5;
  --accent-400: #e5e8f2;
  --accent-color: #b3b8dc;
  --accent-600: #dce1ee;
  --accent-700: #d8ddec;
  --accent-800: #d3d9e9;
  --accent-900: #cbd1e5;
  --accent-A100: #ffffff;
  --accent-A200: #ffffff;
  --accent-A400: #ffffff;
  --accent-A700: #ffffff;

  --warn-50: #fce5e5;
  --warn-100: #f6bebe;
  --warn-200: #f19292;
  --warn-300: #eb6666;
  --warn-400: #e64646;
  --warn-color: #e22525;
  --warn-600: #df2121;
  --warn-700: #da1b1b;
  --warn-800: #d61616;
  --warn-900: #cf0d0d;
  --warn-A100: #fffbfb;
  --warn-A200: #ffc8c8;
  --warn-A400: #ff9595;
  --warn-A700: #ff7b7b;

  --success-50: #e0f8ea;
  --success-100: #b3efcb;
  --success-200: #80e4a9;
  --success-300: #4dd987;
  --success-400: #26d06d;
  --success-color: #00c853;
  --success-600: #00c24c;
  --success-700: #00bb42;
  --success-800: #00b439;
  --success-900: #00a729;
  --success-A100: #d1ffd9;
  --success-A200: #9effaf;
  --success-A400: #6bff85;
  --success-A700: #52ff70;

  --danger-50: #fffaed;
  --danger-100: #fff4d1;
  --danger-200: #ffecb3;
  --danger-300: #ffe494;
  --danger-400: #ffdf7d;
  --danger-color: #ffd966;
  --danger-600: #ffd55e;
  --danger-700: #ffcf53;
  --danger-800: #ffca49;
  --danger-900: #ffc038;
  --danger-A100: #ffffff;
  --danger-A200: #ffffff;
  --danger-A400: #fff5e1;
  --danger-A700: #ffedc8;

  --mdc-icon-button-state-layer-size: 36px;
  --mdc-checkbox-selected-checkmark-color: #fff;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 13px;

  $theme-primary: (50: var(--primary-color-50),
    100: var(--primary-color-100),
    200: var(--primary-color-200),
    300: var(--primary-color-300),
    400: var(--primary-color-400),
    500: var(--primary-color),
    600: var(--primary-color-600),
    700: var(--primary-color-700),
    800: var(--primary-color-800),
    900: var(--primary-color-900),
    A100: var(--primary-color-A100),
    A200: var(--primary-color-A200),
    A400: var(--primary-color-A400),
    A700: var(--primary-color-A700),
    contrast: (50: #000000,
      100: #000000,
      200: #000000,
      300: #000000,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #000000,
      A400: #ffffff,
      A700: #ffffff,
    ),
  );

  $theme-accent: (50: var(--accent-color-50),
    100: var(--accent-color-100),
    200: var(--accent-color-200),
    300: var(--accent-color-300),
    400: var(--accent-color-400),
    500: var(--accent-color),
    600: var(--accent-color-600),
    700: var(--accent-color-700),
    800: var(--accent-color-800),
    900: var(--accent-color-900),
    A100: var(--accent-color-A100),
    A200: var(--accent-color-A200),
    A400: var(--accent-color-A400),
    A700: var(--accent-color-A700),
    contrast: (50: #000000,
      100: #000000,
      200: #000000,
      300: #000000,
      400: #000000,
      500: #000000,
      600: #000000,
      700: #000000,
      800: #000000,
      900: #000000,
      A100: #000000,
      A200: #000000,
      A400: #000000,
      A700: #000000,
    ),
  );

  $theme-warn: (50: var(--warn-color-50),
    100: var(--warn-color-100),
    200: var(--warn-color-200),
    300: var(--warn-color-300),
    400: var(--warn-color-400),
    500: var(--warn-color),
    600: var(--warn-color-600),
    700: var(--warn-color-700),
    800: var(--warn-color-800),
    900: var(--warn-color-900),
    A100: var(--warn-color-A100),
    A200: var(--warn-color-A200),
    A400: var(--warn-color-A400),
    A700: var(--warn-color-A700),
    contrast: (50: #000000,
      100: #000000,
      200: #000000,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #000000,
      A400: #ffffff,
      A700: #ffffff,
    ),
  );

  $theme-warn: (50: var(--warn-color-50),
    100: var(--warn-color-100),
    200: var(--warn-color-200),
    300: var(--warn-color-300),
    400: var(--warn-color-400),
    500: var(--warn-color),
    600: var(--warn-color-600),
    700: var(--warn-color-700),
    800: var(--warn-color-800),
    900: var(--warn-color-900),
    A100: var(--warn-color-A100),
    A200: var(--warn-color-A200),
    A400: var(--warn-color-A400),
    A700: var(--warn-color-A700),
    contrast: (50: #000000,
      100: #000000,
      200: #000000,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #000000,
      A400: #ffffff,
      A700: #ffffff,
    ),
  );

  $theme-success: (50: var(--warn-color-50),
    100: var(--warn-color-100),
    200: var(--warn-color-200),
    300: var(--warn-color-300),
    400: var(--warn-color-400),
    500: var(--warn-color),
    600: var(--warn-color-600),
    700: var(--warn-color-700),
    800: var(--warn-color-800),
    900: var(--warn-color-900),
    A100: var(--warn-color-A100),
    A200: var(--warn-color-A200),
    A400: var(--warn-color-A400),
    A700: var(--warn-color-A700),
    contrast: (50: #000000,
      100: #000000,
      200: #000000,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #000000,
      A400: #ffffff,
      A700: #ffffff,
    ),
  );


  $app-primary: mat.define-palette($theme-primary);
  $app-accent: mat.define-palette($theme-accent);
  $app-warn: mat.define-palette($theme-warn);
  $app-success: mat.define-palette($theme-success);

  $app-typography: mat-typography-config($title: mat-typography-level(24px, 32px, bold));

  $my-custom-typography-config: mat.define-typography-config($headline-1: mat.define-typography-level(112px, 112px, 300),
    $headline-2: mat.define-typography-level(56px, 56px, 400),
    $headline-3: mat.define-typography-level(45px, 48px, 400),
    $headline-4: mat.define-typography-level(34px, 40px, 400),
    $headline-5: mat.define-typography-level(24px, 32px, 400),
    $subtitle-2: mat.define-typography-level(13px, 13px, 400),
    $body-1: mat.define-typography-level(13px, 18px, 400));

  @mixin app-typography($typography: $app-typography) {
    @include mat-card-typography($typography);
    @include app-root-typography($typography);
  }

  $app-theme: mat.define-light-theme((color: (primary: $app-primary,
          accent: $app-accent,
          warn: $app-warn,
        ),
      ));

  //@include mat.all-component-themes($app-theme);
  @include mat.all-component-typographies($my-custom-typography-config);
  @include mat.core-theme($app-theme);
  @include mat.autocomplete-theme($app-theme);
  @include mat.button-theme($app-theme);
  @include mat.button-toggle-theme($app-theme);
  @include mat.card-theme($app-theme);
  @include mat.checkbox-theme($app-theme);
  @include mat.chips-theme($app-theme);
  @include mat.dialog-theme($app-theme);
  @include mat.expansion-theme($app-theme);
  @include mat.form-field-theme($app-theme);
  @include mat.grid-list-theme($app-theme);
  @include mat.icon-theme($app-theme);
  @include mat.input-theme($app-theme);
  @include mat.list-theme($app-theme);
  @include mat.menu-theme($app-theme);
  @include mat.paginator-theme($app-theme);
  @include mat.progress-bar-theme($app-theme);
  @include mat.progress-spinner-theme($app-theme);
  @include mat.select-theme($app-theme);
  @include mat.slide-toggle-theme($app-theme);
  @include mat.slider-theme($app-theme);
  @include mat.snack-bar-theme($app-theme);
  @include mat.stepper-theme($app-theme);
  @include mat.table-theme($app-theme);
  @include mat.tabs-theme($app-theme);
  @include mat.toolbar-theme($app-theme);
  @include mat.tooltip-theme($app-theme);
  @include mat.tree-theme($app-theme);
  @include mat.badge-theme($app-theme);

  .mat-mdc-form-field:not(.mat-mdc-paginator-page-size-select) {
    //font-size: 12px; // example
    //height: 75px;

    .mat-mdc-form-field-infix {
      //padding-top: 24px !important;
      // min-height: auto;
      height: 50px;
    }
  }

  .mdc-button {
    white-space: nowrap;

    &.mat-primary {
      color: mat.get-color-from-palette($app-primary, 500-contrast);
    }

    &.mat-accent {
      color: mat.get-color-from-palette($app-accent, 500-contrast);
    }

    &.mat-warn {
      color: mat.get-color-from-palette($app-primary, 500-contrast);
    }

    &.mat-success {
      background-color: mat.get-color-from-palette($app-success, 500);
      color: mat.get-color-from-palette($app-success, 500-contrast);
    }

    &.mat-error {
      background-color: mat.get-color-from-palette($app-warn, 500);
      color: mat.get-color-from-palette($app-warn, 500-contrast);
    }

    .mdc-button__label {
      display: flex !important;
      align-items: center !important;
  }
  }

  .mdc-icon-button {
    white-space: nowrap;
    padding: 0;
    width: var(--mdc-icon-button-state-layer-size);
    height: var(--mdc-icon-button-state-layer-size);
    display: flex;
    justify-content: center;
    align-items: center;

    &.mat-primary {
      color: mat.get-color-from-palette($app-primary, 500-contrast);
    }

    &.mat-accent {
      color: mat.get-color-from-palette($app-primary, 500-contrast);
    }

    &.mat-warn {
      color: mat.get-color-from-palette($app-primary, 500-contrast);
    }

    &.mat-success {
      color: mat.get-color-from-palette($app-success, 500);
    }

    &.mat-error {
      color: mat.get-color-from-palette($app-warn, 500);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--primary-color);
  }

  .toggle {
    .mat-button-toggle-label-content {
      line-height: 38px;
    }
  }

  mat-card {
    padding: 16px;
  }

  mat-card-title {
    color: var(--primary-color);
    font-size: 24px;
  }

  mat-toolbar {
    font-size: 1em;
  }

  mat-card-subtitle,
  mat-card-content {
    font-size: 0.9em;
  }

  mat-form-field {
    &.filter-wrapper {
      display: none;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field {
      //background-color: transparent;
    }
  }

  .mat-mdc-card-content {
    padding: 0;
  }

  .mat-expansion-panel-header {
    padding: 0;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  table.mat-mdc-table tr {

    &.mat-mdc-row {
      height: auto;
    }

    &.mat-mdc-header-row {
      height: 46px;
      background-color: var(--primary-50);
    }

    td.mat-mdc-cell,
    th.mat-header-cell {
      border: 1px solid var(--primary-lighter-color) !important;
    }


    button.mdc-icon-button.mat-mdc-icon-button {
      width: 30px;
      height: 30px;
      line-height: 30px;
      padding: 4px;
    }

    th.mat-mdc-header-cell:last-of-type {
      padding: 0;
    }

    th.mat-mdc-header-cell:first-of-type,
    td.mat-cell:first-of-type {
      padding: 0;
    }

    .mat-mdc-header-cell,
    .mat-mdc-cell {
      padding: 0;
    }

    td.mat-mdc-cell {
      white-space: nowrap;
    }
  }

  mat-form-field:not(.no-margin):not(.mat-mdc-paginator-page-size-select) {
    // margin-bottom: 10px;
    margin-bottom: 1rem;
  }

  .mat-mdc-paginator-container {
    min-height: auto;
  }

  .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
    background-color: inherit;
  }

  .mat-mdc-checkbox .mdc-checkbox svg.mdc-checkbox__checkmark {
    color: mat.get-color-from-palette($app-primary, 500-contrast) !important;
  }

  .mdc-checkbox__mixedmark {
    border-color: mat.get-color-from-palette($app-primary, 500-contrast) !important;
  }
}

.engagement-table-wrapper {
  --mdc-icon-button-state-layer-size: 28px;
}

.mat-sort-header-content {
  width: 100%;
}

.mdc-data-table__table.resize {
  min-width: auto;
}

.mat-calendar {
  background-color: #fff;
}